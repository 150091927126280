<template>
  <div
    v-show="isLoading"
    :class="$style.container"
    :style="{ backgroundImage: 'url(' + backgroundImg + ')' }"
  >
    <div :class="$style.content">
      <div :class="$style['music-page']">
        <div :class="$style.rows">
          <div :class="$style.player">
            <HaiPlayer :url="musicUrl" bar autoPlay></HaiPlayer>
          </div>
          <div :class="$style.download" @click="handleDownload">
            <img src="../assets/images/download.png" :alt="$t('download')" />
            <span>{{ $t('download') }}</span>
          </div>
        </div>
        <HaiButton @click="$router.push({ path: '/' })">{{
          $t('backHome')
        }}</HaiButton>
      </div>
    </div>
    <HaiFooter />
  </div>
</template>

<script>
import HaiButton from '@/components/HaiButton'
import HaiPlayer from '@/components/HaiPlayer'
import HaiFooter from '@/components/HaiFooter'
import { GetComposer } from '@/api/create'
import { url } from '@/api/api-list'

export default {
  name: 'Compose',
  components: {
    HaiButton,
    HaiPlayer,
    HaiFooter
  },
  data() {
    return {
      musicUrl: '',
      mp3Bg: '',
      midi: '',
      isLoading: false
    }
  },
  computed: {
    backgroundImg() {
      return this.mp3Bg
    }
  },
  created() {
    this.getCompose()
  },
  methods: {
    async getCompose() {
      this.$Loading.start()

      const { data } = await GetComposer({
        style: this.$route.query.style
      })
      // 此处产品要求慢点展示
      let timer = setTimeout(() => {
        this.musicUrl = url.baseUrl + data.mp3
        this.midi = url.baseUrl + data.midi
        // this.musicUrl = 'https://xiaohi.xingzheai.cn' + data.mp3
        // this.midi = 'https://xiaohi.xingzheai.cn' + data.midi
        this.mp3Bg = data.img
        this.isLoading = true
        this.$Loading.finish()
        clearTimeout(timer)
      }, 8000)
    },
    handleDownload() {
      window.open(this.midi, '_self')
    }
  }
}
</script>

<style lang="scss" module>
.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 10;
  background-size: cover;
  background-repeat: no-repeat;
}
.content {
  width: 1200px;
  margin: 0 auto;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  text-align: center;
  .music-page {
    height: 100px;
    margin-top: 480px;
    position: relative;
    .rows {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      .player {
        flex: 1;
      }
      .download {
        width: 121px;
        display: flex;
        justify-content: flex-start;
        align-items: center;
        cursor: pointer;
        margin-left: 50px;
        > img {
          width: 34px;
          height: 35px;
          margin-right: 10px;
        }
        > span {
          width: 74px;
          height: 26px;
          font-size: 16px;
          font-weight: 400;
          color: rgba(83, 145, 249, 1);
          line-height: 26px;
        }
      }
    }
  }
}
</style>
