<template>
  <div class="footer">
    <p class="company-rights">Copyright ©{{ year }} {{ $t('app.company') }}</p>

    <p class="company-email">
      <!-- {{ $t('app.learnMore') }}：<a :href="jumpDomain" target="_blank">{{
        host
      }}</a -->
      {{ $t('app.business') }}：<a
        :href="`mailto:${contact_email}`"
        target="_blank"
        >{{ contact_email }}</a
      >
    </p>
  </div>
</template>
<script>
import WebConfig from '../../config/webConfig'
export default {
  name: 'HaiFooter',
  data: () => ({
    jumpDomain: WebConfig.hi_domain,
    host: WebConfig.hi_host,
    contact_email: WebConfig.contact_email,
    year: new Date().getFullYear()
  })
}
</script>
<style lang="scss">
.footer {
  opacity: 0.3;
  font-family: 'PingFang SC', 'Microsoft YaHei';
  font-size: 14px;
  color: #ffffff;
  width: 100%;
  text-align: center;
  margin: 0 auto;
  position: fixed;
  left: 0;
  right: 0;
  bottom: 10px;
  a {
    font-size: 14px;
    color: #ffffff;
    @media (max-width: 1440px) {
      font-size: 12px;
    }
  }
  @media (max-width: 1440px) {
    font-size: 12px;
  }
  .company-email {
    padding-top: 5px;
  }
}
</style>
